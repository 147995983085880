<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1805 22.5H11.5C10.9477 22.5 10.5 22.9477 10.5 23.5V30.1682C10.5 30.6502 10.8439 31.0637 11.3179 31.1515L21.7295 33.0795C23.1909 33.3502 24.7008 33.0687 25.9666 32.2898L35.8625 26.2C36.5461 25.7793 36.8955 24.9774 36.7381 24.1903C36.5927 23.4634 36.0415 22.8854 35.3224 22.7056L35.2378 22.6845C34.7617 22.5654 34.2587 22.6206 33.8198 22.8401L26 26.75C26 26.0081 25.5133 25.354 24.8027 25.1408L16.7849 22.7355C16.2644 22.5793 15.7239 22.5 15.1805 22.5Z"
      fill="#FFE100"
    />
    <path
      d="M18.5 27L24.761 27.8348C25.417 27.9223 26 27.4119 26 26.75V26.75M26 26.75V26.75C26 26.0081 25.5133 25.354 24.8027 25.1408L16.7849 22.7355C16.2644 22.5793 15.7239 22.5 15.1805 22.5H11.5C10.9477 22.5 10.5 22.9477 10.5 23.5V30.1682C10.5 30.6502 10.8439 31.0637 11.3179 31.1515L21.7295 33.0795C23.1909 33.3502 24.7008 33.0687 25.9666 32.2898L35.8625 26.2C36.5461 25.7793 36.8955 24.9774 36.7381 24.1903V24.1903C36.5927 23.4634 36.0415 22.8854 35.3224 22.7056L35.2378 22.6845C34.7617 22.5654 34.2587 22.6206 33.8198 22.8401L26 26.75Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="6"
      y="21.5"
      width="6"
      height="11"
      rx="1"
      fill="#FFE100"
      stroke="black"
      stroke-linejoin="round"
    />
    <circle cx="26" cy="14.5" r="7.5" fill="#FFE100" stroke="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.3341 10.5H24.6674V13.1665H22V15.8331H24.6674V18.5H27.3341V15.8331H30V13.1665H27.3341V10.5Z"
      fill="white"
    />
    <path
      d="M24.6674 10.5V10C24.3913 10 24.1674 10.2239 24.1674 10.5H24.6674ZM27.3341 10.5H27.8341C27.8341 10.2239 27.6102 10 27.3341 10V10.5ZM24.6674 13.1665V13.6665C24.9436 13.6665 25.1674 13.4426 25.1674 13.1665H24.6674ZM22 13.1665V12.6665C21.7239 12.6665 21.5 12.8903 21.5 13.1665H22ZM22 15.8331H21.5C21.5 16.1093 21.7239 16.3331 22 16.3331V15.8331ZM24.6674 15.8331H25.1674C25.1674 15.557 24.9436 15.3331 24.6674 15.3331V15.8331ZM24.6674 18.5H24.1674C24.1674 18.7761 24.3913 19 24.6674 19V18.5ZM27.3341 18.5V19C27.6102 19 27.8341 18.7761 27.8341 18.5H27.3341ZM27.3341 15.8331V15.3331C27.0579 15.3331 26.8341 15.557 26.8341 15.8331H27.3341ZM30 15.8331V16.3331C30.2761 16.3331 30.5 16.1093 30.5 15.8331H30ZM30 13.1665H30.5C30.5 12.8903 30.2761 12.6665 30 12.6665V13.1665ZM27.3341 13.1665H26.8341C26.8341 13.4426 27.0579 13.6665 27.3341 13.6665V13.1665ZM24.6674 11H27.3341V10H24.6674V11ZM25.1674 13.1665V10.5H24.1674V13.1665H25.1674ZM22 13.6665H24.6674V12.6665H22V13.6665ZM22.5 15.8331V13.1665H21.5V15.8331H22.5ZM24.6674 15.3331H22V16.3331H24.6674V15.3331ZM25.1674 18.5V15.8331H24.1674V18.5H25.1674ZM27.3341 18H24.6674V19H27.3341V18ZM26.8341 15.8331V18.5H27.8341V15.8331H26.8341ZM30 15.3331H27.3341V16.3331H30V15.3331ZM29.5 13.1665V15.8331H30.5V13.1665H29.5ZM27.3341 13.6665H30V12.6665H27.3341V13.6665ZM26.8341 10.5V13.1665H27.8341V10.5H26.8341Z"
      fill="black"
    />
  </svg>
</template>
