<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      stroke="#333"
      stroke-linecap="round"
      d="M28 21v8c0 1.657-1.343 3-3 3H5c-1.657 0-3-1.343-3-3V13c0-1.657 1.343-3 3-3h20c1.657 0 3 1.343 3 3v2.5"
    />
    <circle cx="15" cy="16" r="1.5" fill="#fff" stroke="#333" />
    <path
      stroke="#333"
      stroke-linecap="round"
      d="M19 23c0-2.21-1.79-4-4-4s-4 1.79-4 4M5 27h20"
    />
    <circle cx="6" cy="27" r="1.5" fill="#FEDE07" stroke="#333" />
    <circle cx="27" cy="12" r="6" fill="#4384FF" />
    <path
      fill="#fff"
      d="M26.268 9.489c-.332-.212-.768.027-.768.422v4.178c0 .395.436.634.768.422l3.284-2.09c.308-.196.308-.647 0-.843l-3.284-2.09z"
    />
  </svg>
</template>
