<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="15" y="9" width="9" height="4" rx="0.5" stroke="black" />
    <rect
      x="6"
      y="12.5"
      width="27"
      height="19"
      rx="1"
      fill="#FFE100"
      stroke="black"
    />
    <path d="M6 18.5H33" stroke="black" stroke-linejoin="round" />
    <rect
      x="17.5"
      y="17.5"
      width="4"
      height="3"
      fill="white"
      stroke="black"
      stroke-linejoin="round"
    />
  </svg>
</template>
