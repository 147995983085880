<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 15.5V11.1667C25 8.58934 22.7614 6.5 20 6.5C17.2386 6.5 15 8.58934 15 11.1667V15.5"
      stroke="black"
      stroke-linejoin="round"
    />
    <path
      d="M8.2255 14.3781C8.28707 13.8767 8.71289 13.5 9.21804 13.5H30.782C31.2871 13.5 31.7129 13.8767 31.7745 14.3781L33.8622 31.3781C33.9354 31.9743 33.4703 32.5 32.8697 32.5H7.13032C6.52969 32.5 6.06456 31.9743 6.13778 31.3781L8.2255 14.3781Z"
      fill="#FFE100"
      stroke="black"
      stroke-linejoin="round"
    />
    <circle cx="15" cy="16.5" r="1" fill="white" stroke="black" />
    <circle cx="25" cy="16.5" r="1" fill="white" stroke="black" />
  </svg>
</template>
