<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17292 27.426L11.027 19.0829C11.1286 18.6253 11.5345 18.2998 12.0031 18.2998H27.9988C28.4675 18.2998 28.8733 18.6253 28.975 19.0829L30.829 27.426C30.9298 27.8793 30.7052 28.3423 30.2869 28.5439L20.435 33.2907C20.1608 33.4228 19.8412 33.4228 19.5669 33.2907L9.71505 28.5439C9.29674 28.3423 9.07219 27.8793 9.17292 27.426Z"
      fill="#FFE100"
      stroke="black"
      stroke-linejoin="round"
    />
    <path d="M34 15.5498V20.5498" stroke="black" stroke-linejoin="round" />
    <circle cx="34" cy="21.5" r="1.5" fill="white" stroke="black" />
    <path
      d="M5.36764 14.1511L19.5862 7.68809C19.8491 7.56858 20.1509 7.56858 20.4138 7.68809L34.6324 14.1511C35.391 14.4959 35.4198 15.563 34.6807 15.9482L20.4622 23.3591C20.1726 23.5101 19.8274 23.5101 19.5378 23.3591L5.31925 15.9482C4.58023 15.563 4.60896 14.4959 5.36764 14.1511Z"
      fill="#FFE100"
      stroke="black"
      stroke-linejoin="round"
    />
  </svg>
</template>
