
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref
} from "vue";
import { useRoute } from "vue-router";
import TitleSlide from "@/components/atoms/icon/TitleSlide/TitleSlide.vue";
import SubNavArrowIcon from "@/components/atoms/icon/SubNavArrowIcon/SubNavArrowIcon.vue";
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
import IrTitle from "@/components/atoms/element/IrTitle/IrTitle.vue";
import DropdownListIcon from "@/components/atoms/icon/DropdownListIcon/DropdownListIcon.vue";
import AppButton from "@/components/atoms/button/AppButton/AppButton.vue";
type SolutionType =
  | "SolutionEducation"
  | "SolutionMedia"
  | "SolutionEnterprise"
  | "SolutionCommerce"
  | "SolutionHealthcare";

export default defineComponent({
  components: {
    AppRouterLink,
    TitleSlide,
    IrTitle,
    DropdownListIcon,
    AppButton,
    SubNavArrowIcon
  },
  props: {
    items: {
      type: Object,
      requre: true
    }
  },
  setup() {
    const route = useRoute();
    const fullPath = computed(() => route.path);
    const irName = computed(() => route.name);
    const isActive = ref(false);
    const isFixed = ref(false);
    const isStable = ref(false);
    const nav = ref() as Ref<HTMLElement>;
    const selectTitle = (ir: SolutionType) => {
      const selectTable = {
        SolutionEducation: "교육",
        SolutionMedia: "미디어",
        SolutionEnterprise: "엔터프라이즈",
        SolutionCommerce: "커머스",
        SolutionHealthcare: "헬스케어"
      };
      return selectTable[ir];
    };
    const menuToggle = () => (isActive.value = !isActive.value);
    const effect = () => {
      isFixed.value = nav.value?.getBoundingClientRect().top < 10;
      isStable.value = 56 >= nav.value?.getBoundingClientRect().top;
    };
    onMounted(() => window.addEventListener("scroll", effect));
    onUnmounted(() => window.removeEventListener("scroll", effect));

    return {
      fullPath,
      irName,
      isActive,
      menuToggle,
      nav,
      isFixed,
      isStable,
      selectTitle
    };
  }
});
