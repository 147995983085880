
import { defineComponent } from "vue";
const list = [
  {
    name: "livecall",
    title: "Live Call"
  },
  {
    name: "livecast",
    title: "Live Cast"
  },
  {
    name: "liveconference",
    title: "Live Conference"
  }
];
export default defineComponent({
  props: {
    irName: {
      type: String
    }
  },
  setup(props) {
    const nameCheck = (name: string) =>
      props.irName?.toLowerCase().indexOf(name) !== -1;

    return {
      nameCheck,
      list
    };
  }
});
