<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20.5" r="13.5" fill="#FFE100" stroke="black" />
    <path
      d="M17.153 17.0917C17.153 16.3191 17.9918 15.8383 18.6585 16.2289L24.5981 19.7082C25.2575 20.0945 25.2575 21.0477 24.5981 21.4339L18.6585 24.9133C17.9918 25.3038 17.153 24.823 17.153 24.0504L17.153 17.0917Z"
      fill="white"
    />
    <path
      d="M18.6585 24.9133L18.4057 24.4818L18.6585 24.9133ZM24.5981 19.7082L24.8508 19.2768L24.5981 19.7082ZM24.5981 21.4339L24.3454 21.0025L24.5981 21.4339ZM18.4057 16.6603L24.3454 20.1396L24.8508 19.2768L18.9112 15.7974L18.4057 16.6603ZM24.3454 21.0025L18.4057 24.4818L18.9112 25.3447L24.8508 21.8654L24.3454 21.0025ZM17.653 24.0504L17.653 17.0917L16.653 17.0917L16.653 24.0504L17.653 24.0504ZM18.4057 24.4818C18.0724 24.6771 17.653 24.4367 17.653 24.0504L16.653 24.0504C16.653 25.2093 17.9112 25.9305 18.9112 25.3447L18.4057 24.4818ZM24.3454 20.1396C24.6751 20.3328 24.6751 20.8094 24.3454 21.0025L24.8508 21.8654C25.8399 21.286 25.8399 19.8562 24.8508 19.2768L24.3454 20.1396ZM18.9112 15.7974C17.9112 15.2117 16.653 15.9328 16.653 17.0917L17.653 17.0917C17.653 16.7054 18.0724 16.465 18.4057 16.6603L18.9112 15.7974Z"
      fill="black"
    />
  </svg>
</template>
