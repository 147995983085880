<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      stroke="#333"
      stroke-linecap="round"
      d="M28 16.5V13c0-1.657-1.343-3-3-3H5c-1.657 0-3 1.343-3 3v12c0 1.657 1.343 3 3 3H25c1.657 0 3-1.343 3-3v-4.15"
    />
    <circle cx="15" cy="16.462" r="1.5" fill="#fff" stroke="#333" />
    <path
      stroke="#333"
      stroke-linecap="round"
      d="M20 25c0-2.761-2.239-5-5-5s-5 2.239-5 5M7 32h16M13 28v4M17 28v4"
    />
    <path
      stroke="#333"
      stroke-linecap="round"
      stroke-width="1.5"
      d="M24.16 12.88h2.56"
    />
    <rect width="12" height="12" x="21" y="6" fill="#4384FF" rx="3" />
    <path
      fill="#fff"
      d="M27 11c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1zM27 12c-1.556 0-2.835 1.184-2.985 2.7-.017.165.12.3.285.3h5.4c.166 0 .302-.135.285-.3-.15-1.516-1.43-2.7-2.985-2.7z"
    />
  </svg>
</template>
