
import { defineComponent } from "vue";
import DropdownLivecallIcon from "@/components/atoms/icon/DropdownLivecallIcon/DropdownLivecallIcon.vue";
import DropdownLivecastIcon from "@/components/atoms/icon/DropdownLivecastIcon/DropdownLivecastIcon.vue";
import DropdownLiveconfIcon from "@/components/atoms/icon/DropdownLiveconfIcon/DropdownLiveconfIcon.vue";
import SolutionEducationIcon from "@/components/atoms/icon/SolutionEducationIcon/SolutionEducationIcon.vue";
import SolutionMediaIcon from "@/components/atoms/icon/SolutionMediaIcon/SolutionMediaIcon.vue";
import SolutionEnterpriseIcon from "@/components/atoms/icon/SolutionEnterpriseIcon/SolutionEnterpriseIcon.vue";
import SolutionCommerceIcon from "@/components/atoms/icon/SolutionCommerceIcon/SolutionCommerceIcon.vue";
import SolutionHealthcareIcon from "@/components/atoms/icon/SolutionHealthcareIcon/SolutionHealthcareIcon.vue";

export default defineComponent({
  components: {
    DropdownLivecallIcon,
    DropdownLivecastIcon,
    DropdownLiveconfIcon,
    SolutionEducationIcon,
    SolutionMediaIcon,
    SolutionEnterpriseIcon,
    SolutionCommerceIcon,
    SolutionHealthcareIcon
  },
  props: {
    iconType: {
      type: String
    }
  }
});
