<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      stroke="#333"
      stroke-linecap="round"
      d="M25 21.5V32c0 1.657-1.343 3-3 3H7c-1.657 0-3-1.343-3-3V10c0-1.657 1.343-3 3-3h15c1.657 0 3 1.343 3 3v4"
    />
    <circle cx="14.5" cy="17.5" r="2" fill="#fff" stroke="#333" />
    <path
      stroke="#333"
      stroke-linecap="round"
      d="M21 29c0-3.866-2.91-7-6.5-7S8 25.134 8 29"
    />
    <path
      fill="#4384FF"
      d="M32.535 11.046L30 12.633v3.734l2.535 1.587c.208.13.465-.032.465-.288v-6.33c0-.258-.257-.42-.465-.29zM20.854 19h8.292c.472 0 .854-.39.854-.873v-7.254c0-.483-.382-.873-.854-.873h-8.292c-.472 0-.854.39-.854.873v7.254c0 .483.384.873.854.873z"
    />
    <path
      fill="#fff"
      d="M23.5 15c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5z"
    />
  </svg>
</template>
