import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "screen-out"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      (_ctx.nameCheck(item.name))
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.title), 1))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}