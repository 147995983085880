import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownLivecallIcon = _resolveComponent("DropdownLivecallIcon")!
  const _component_DropdownLivecastIcon = _resolveComponent("DropdownLivecastIcon")!
  const _component_DropdownLiveconfIcon = _resolveComponent("DropdownLiveconfIcon")!
  const _component_SolutionEducationIcon = _resolveComponent("SolutionEducationIcon")!
  const _component_SolutionMediaIcon = _resolveComponent("SolutionMediaIcon")!
  const _component_SolutionEnterpriseIcon = _resolveComponent("SolutionEnterpriseIcon")!
  const _component_SolutionCommerceIcon = _resolveComponent("SolutionCommerceIcon")!
  const _component_SolutionHealthcareIcon = _resolveComponent("SolutionHealthcareIcon")!

  return (_ctx.iconType === 'livecall')
    ? (_openBlock(), _createBlock(_component_DropdownLivecallIcon, { key: 0 }))
    : (_ctx.iconType === 'livecast')
      ? (_openBlock(), _createBlock(_component_DropdownLivecastIcon, { key: 1 }))
      : (_ctx.iconType === 'liveconference')
        ? (_openBlock(), _createBlock(_component_DropdownLiveconfIcon, { key: 2 }))
        : (_ctx.iconType === 'education')
          ? (_openBlock(), _createBlock(_component_SolutionEducationIcon, { key: 3 }))
          : (_ctx.iconType === 'media')
            ? (_openBlock(), _createBlock(_component_SolutionMediaIcon, { key: 4 }))
            : (_ctx.iconType === 'enterprise')
              ? (_openBlock(), _createBlock(_component_SolutionEnterpriseIcon, { key: 5 }))
              : (_ctx.iconType === 'commerce')
                ? (_openBlock(), _createBlock(_component_SolutionCommerceIcon, { key: 6 }))
                : (_openBlock(), _createBlock(_component_SolutionHealthcareIcon, { key: 7 }))
}