import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubNavMobile = _resolveComponent("SubNavMobile")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SubNavMobile, {
      items: _ctx.items,
      class: "mt-36 mb-14"
    }, null, 8, ["items"]),
    _createVNode(_component_router_view, { name: "mobile" })
  ], 64))
}